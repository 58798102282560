import { useUIResourcePool } from '../ducks/common/resources';

export const enum LEGAL_NAME {
  CONTACT_US = 'contactUs',
  GROUP_BOOKING = 'groupBooking',
  HELP_AND_SUPPORT = 'helpAndSupport',
  PRIVACY_POLICY = 'privacyPolicy',
}

export const UI_RESOURCE_POOL = {
  contactUs: 'Book.ContactUs.URL',
  groupBooking: 'Book.GroupBooking.URL',
  helpAndSupport: 'Book.HelpAndSupport.URL',
  privacyPolicy: 'Book.PrivacyPolicy.URL',
} as const;

export const useLegalLinks = (legalName: string) => {
  const { contactUs, groupBooking, helpAndSupport, privacyPolicy } = useUIResourcePool(UI_RESOURCE_POOL);
  switch (legalName) {
    case LEGAL_NAME.CONTACT_US:
      return contactUs;
    case LEGAL_NAME.GROUP_BOOKING:
      return groupBooking;
    case LEGAL_NAME.HELP_AND_SUPPORT:
      return helpAndSupport;
    case LEGAL_NAME.PRIVACY_POLICY:
      return privacyPolicy;
    default:
      return undefined;
  }
};
