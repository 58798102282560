'use client';

import { usePathname } from 'next/navigation';

import ShareEmail from '@publicImages/svg/share-email.svg?icon';

import UIResource from '@/components/UIResource';
import { BookingChannelTypes } from '@/constants/settings';
import { useUIResource } from '@/ducks/common/resources';
import { selectAgentDetails } from '@/ducks/fm/selectors';
import { routes } from '@/ducks/routes';
import { useAppSelector } from '@/store';

import AboutTooltip from './Tooltip';

import styles from './FMBanner.module.scss';

const Details = () => {
  const emailSubject = useUIResource('FMLinking.Banner.email.subject');
  const { agentName = '', code: phoneCode = '', email, phoneNumber = '' } = useAppSelector(selectAgentDetails) ?? {};

  const mailtoUrl = email ? `mailto:${email}?subject=${emailSubject}` : undefined;
  const phone = `+${phoneCode}${phoneNumber}`;
  const phoneUrl = phoneNumber ? `tel:${phone}` : undefined;
  return (
    <div className={styles.root}>
      <span className={styles.title}>
        <UIResource id="FMLinking.Banner.Title" /> {agentName}
      </span>
      <AboutTooltip agentName={agentName} className={styles.aboutTooltip} />
      <a aria-label={`${agentName}'s email`} className={styles.email} href={mailtoUrl}>
        <ShareEmail className={styles.icon} />
        {agentName}
      </a>
      {phoneUrl && (
        <a aria-label={`${agentName}'s phone is ${phone}`} className={styles.phone} href={phoneUrl}>
          {phone}
        </a>
      )}
    </div>
  );
};

const FMBanner = () => {
  const { agentName, bookingChannel, isValid } = useAppSelector(selectAgentDetails) ?? {};
  const pathname = usePathname();

  const isEnable =
    isValid &&
    bookingChannel === BookingChannelTypes.fmLink &&
    !!agentName &&
    pathname !== routes.planner.dnsError.path;

  if (!isEnable) return null;

  return <Details />;
};

export default FMBanner;
