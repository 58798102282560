import { createElement, useMemo, useRef } from 'react';

import { Arrow, Content, Portal, Provider, Root, Trigger } from '@radix-ui/react-tooltip';
import cn from 'classnames';

import type { TBoxSide, TOptional, TSideAlign } from '@/types/common';

import delay from '@/helpers/delay';
import { isServerSide } from '@/helpers/isServerSide';
import getDefaultScreenReader, { ScreenReader } from '@/helpers/userAgent/getDefaultScreenReader';
import useOpenable from '@/hooks/useOpenable';

import styles from './Tooltip.module.scss';

// Use --tooltip-* css variables on the parent node(s) to tune the appearance

export const enum TooltipMode {
  INFO = 'info',
  // TODO: Add other modes here
}

export const BEAK_SIZE = 10;
export const SCREEN_READER = getDefaultScreenReader();

export const DEFAULT_ALIGN = 'center' as const;
export const DEFAULT_SIDE = 'top' as const;
export const DEFAULT_OPENER = '[...]';

export const STANDARD_OPENERS = {
  [TooltipMode.INFO]: ({ ariaLabel }: { ariaLabel?: string }) => (
    <>
      <span className="sr-only">
        {ariaLabel || 'More information'}
        {SCREEN_READER === ScreenReader.NVDA && ', tooltip'}.
      </span>
    </>
  ),
  // TODO: Add other modes here
};

const getStandardOpener = (mode: TOptional<TooltipMode>, ariaLabel: TOptional<string>): TOptional<React.ReactNode> => {
  if (mode) {
    const opener = STANDARD_OPENERS[mode];
    if (typeof opener === 'function') {
      return createElement(opener, { ariaLabel });
    }
    return opener;
  }
};

const getElement = (target: TOptional<HTMLElement | string>): TOptional<HTMLElement> => {
  if (target && !isServerSide()) {
    if (typeof target === 'string') {
      return document.querySelector<HTMLElement>(target) ?? undefined;
    }
    return target;
  }
};

type TooltipProps = React.ComponentProps<typeof Root>;
type PopperContentProps = React.ComponentProps<typeof Content>;

type TProp = Omit<TooltipProps, 'onOpenChange' | 'open'> & {
  align?: TSideAlign;
  'aria-label'?: string;
  boundary?: HTMLElement | string;
  children: React.ReactNode;
  collisionPadding?: PopperContentProps['collisionPadding'];
  isInline?: boolean;
  mode?: TooltipMode;
  onClose?: () => void;
  onOpen?: () => void;
  opener?: React.ReactNode;
  openerClassName?: string;
  popupClassName?: string;
  portal?: boolean | HTMLElement | string;
  side?: TBoxSide;
};

const Tooltip = ({
  align = DEFAULT_ALIGN,
  ['aria-label']: ariaLabel,
  boundary,
  children,
  collisionPadding = 0,
  isInline,
  mode,
  onClose,
  onOpen,
  opener,
  openerClassName,
  popupClassName,
  portal,
  side = DEFAULT_SIDE,
  ...restRootProps
}: TProp) => {
  const { close, isOpened, open } = useOpenable();
  const strikeRef = useRef<boolean>();

  const collisionBoundary = isOpened ? getElement(boundary) : null;

  const [container, withPortal] = useMemo(() => {
    if (typeof portal === 'boolean') {
      return [undefined, portal];
    }
    const node = getElement(portal);
    return [node, !!node];
  }, [portal]);

  const onOpenChange = (isOpen: boolean) => {
    (isOpen ? open : close)();
    if (!isOpen) {
      strikeRef.current = undefined;
    }
    (isOpen ? onOpen : onClose)?.();
  };

  const strikeOpen = () => {
    open();
    strikeRef.current = true;
  };

  const openAsync = () => {
    // timeout needed to run this after onOpenChange to prevent bug on mobile
    delay(300).then(strikeOpen);
  };

  const onContentPointerDown = (event: React.SyntheticEvent) => {
    if (strikeRef.current) {
      event.preventDefault();
    }
  };

  const content = (
    <Content
      align={align}
      alignOffset={Math.round(BEAK_SIZE / 2)}
      avoidCollisions
      className={cn(styles.popup, popupClassName, { [`_${mode}`]: mode })}
      collisionBoundary={collisionBoundary}
      collisionPadding={collisionPadding}
      onPointerDown={onContentPointerDown}
      side={side}
    >
      {children}
      <Arrow height={BEAK_SIZE} width={2 * BEAK_SIZE} />
    </Content>
  );
  return (
    <Provider delayDuration={300}>
      <Root {...restRootProps} onOpenChange={onOpenChange} open={isOpened}>
        <Trigger asChild onBlur={close} onClick={strikeOpen} onFocus={openAsync}>
          <span
            // Set aria-describedby to `undefined` to avoid double pronunciation by screen-readers
            aria-describedby={undefined}
            className={cn(styles.opener, openerClassName, { _inline: isInline, [`_${mode}`]: mode })}
            role="tooltip"
            tabIndex={0}
          >
            {opener || getStandardOpener(mode, ariaLabel) || DEFAULT_OPENER}
            <span className="sr-only">{children}</span>
          </span>
        </Trigger>
        {!withPortal && content}
        {withPortal && <Portal container={container}>{content}</Portal>}
      </Root>
    </Provider>
  );
};

export default Tooltip;
