import cn from 'classnames';

import Tooltip, { TooltipMode } from '@/components/Tooltip';
import UIResource from '@/components/UIResource';
import UIResourceHtml from '@/components/UIResource/Html';
import { resetFmFlow } from '@/ducks/fm/reset';
import { useAppDispatch } from '@/store';
import tagmanager from '@/tagmanager';

import styles from './Tooltip.module.scss';

const onOpenTooltip = () => {
  tagmanager.tracker.common.trackIconClick({ label: tagmanager.trackerConstants.EVENT_LABELS.FM_INFO });
};

type Props = {
  agentName: string;
  className?: string;
};

const FMOptOutTooltip = ({ agentName, className }: Props) => {
  const dispatch = useAppDispatch();

  const optOut = () => {
    dispatch(resetFmFlow());
    tagmanager.tracker.common.trackLinkClick({ label: tagmanager.trackerConstants.EVENT_LABELS.FM_OPT_OUT });
  };

  return (
    <Tooltip
      mode={TooltipMode.INFO}
      onOpen={onOpenTooltip}
      openerClassName={cn(styles.tooltipOpener, className)}
      popupClassName={styles.tooltipContent}
      side="bottom"
    >
      <UIResourceHtml
        className={styles.tooltipParagraph}
        id="FMLinking.tooltip"
        node="span"
        values={{ Name: agentName }}
      />
      <span className={styles.tooltipParagraph}>
        <UIResource id="FMLinking.optOut" />
        <button aria-label="opt out of agent service" className={styles.optOutButton} onClick={optOut}>
          <UIResource id="FMLinking.optOutButton" />
        </button>
      </span>
    </Tooltip>
  );
};

export default FMOptOutTooltip;
